import React from "react";
import Szakmai from "./Szakmai";

const AboutMe = () => {
  return (
    <div id="about" className="about__container">
      <h2 className="rolam">Rólam</h2>
      <Szakmai />
    </div>
  );
};

export default AboutMe;

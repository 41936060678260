import React from "react";

const Footer = () => {
  return (
    <footer>
      <a href="#landing">A lap tetejére</a>
    </footer>
  );
};

export default Footer;
